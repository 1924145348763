import Faq from "../Faq/Faq";
import classes from "./ContactUs.module.css";

const ContactUs = () => {
  return (
    <section id="contact" className={classes.contact}>
      <div className={classes.contactBox}>
        <div className={classes.contentBox}>
          <Faq />
          <div className={classes.mapBox}>
            <iframe
              className={classes.map}
              title="PSIT"
              src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2286.0998693831916!2d80.19252368557257!3d26.44983901167333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1spsit!5e0!3m2!1sen!2sin!4v1733696331476!5m2!1sen!2sin"
              loading="lazy"
              style={{ border: 0 }}
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
