import { Link } from "react-router-dom";
import classes from "./Dropdown.module.css";

const Dropdown = (props) => {
  return (
    <ul className={classes.dropdown}>
      <li className={classes.drops}>
        <Link
          to="/"
          onClick={props.click}
          className={classes.links}
        >
          Culturals
        </Link>
      </li>
      <li className={classes.drops}>
        <Link
          to="/"
          onClick={props.click}
          className={classes.links}
        >
          Workshops
        </Link>
      </li>
      <li className={classes.drops}>
        <Link
          to="/"
          onClick={props.click}
          className={classes.links}
        >
          Games
        </Link>
      </li>
    </ul>
  );
};

export default Dropdown;
