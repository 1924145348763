import classes from "./About.module.css";

const About = () => {
  return (
    <section
      id="about"
      className={classes.aboutSec}
    >
      <div className={classes.about}>
        <div className={classes.details}>
          <h3 className={classes.heading}>About</h3>
          <h2 className={classes.heading1}>Ignitia'25</h2>

          <p className={classes.para}>
          "Innovation is progress in the face of tradition". We've all heard of incredible legends of unsung heroes lost in the pages of Indian history. Today we pledge to carry their legacy forward with Aarohan. Aarohan is a means of paying reverence and giving due credit to India's greatest inventors and original thinkers. We aim to celebrate India's illustrious history of innovations, spanning at least 20 centuries, right from the invention of zero and the decimal number system, to modern day AI. Aarohan shall give fuel to the government's Make In India scheme, paving our way to Aatm-Nirbhar Bharat. Our country now holds the position of the 5th highest global economy. With Aarohan, we are aiming to acclaim the pinnacle of human innovation and development, starting at ground zero level, from which we can only rise upward.
          </p>
        </div>

        <div className={classes.composition}>
          <img
            className={classes.images}
            src="assets/About/ellipse.png"
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default About;
