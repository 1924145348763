// import { motion } from "framer-motion";

import classes from "./Events.module.css";
import Background from "../../UI/Background";
import { Link } from "react-router-dom";
import PrimayBtn from "../Button/Button";

const Events = () => {
  return (
    <section
      className={classes.events}
    >
      <Background className={classes.eventBox}>
          <img
            className={classes.imgBox}
            src="assets/logoish.jpeg"
            alt="Ignitia'25"
          />

        <div className={classes.headingBox}>
          <h3 className={classes.heading}>Ignitia'25</h3>
          <p className={classes.para}>
            The programme includes a variety of activities, including technical,
            non-technical, hackathons, and project contests. The event is
            significant in the fields of creativity motivation and technological
            innovation skills among the new age laureates.
          </p>

          <Link to="/" target="_blank" rel="noopener noreferrer">
            <PrimayBtn text="Explore Events" />
          </Link>
        </div>
      </Background>
    </section>
  );
};

export default Events;
