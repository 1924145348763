import { useState } from "react";
import classes from "./Faq.module.css";

const questions = [
  {
    question: "How can i participate in Ignitia 25?",
    answer:
      "If you are a part of PSIT, you can participate in Ignitia 25. If you are not a part of PSIT, you can participate in the events by registering for the events.",
  },
  {
    question: "What are the dates of Ignitia 25?",
    answer: "March 28, 29, and 30. Events will happen from 9:00 am to 10:00 pm",
  },
  {
    question: "What is the entry fees for Ignitia 25?",
    answer: "The entry for Ignitia 25 is 399(day 2), 499(day 3), 799(day 2&3)",
  },
  {
    question: "Is it possible for one participant to attend any workshop?",
    answer:
      "No, participants can only attend registered workshops due to the scheduling of time.",
  },
];

const Faq = () => {
  const [clicked, setClicked] = useState(null);

  const toggle = (i) => {
    if (clicked === i) {
      return setClicked(null);
    }

    setClicked(i);
  };

  return (
    <section className={classes.faqSection}>
      <div className={classes.heading}>FAQ</div>
      <div className={classes.faq}>
        {questions.map((ques, i) => {
          return (
            <div className={classes.single} onClick={() => toggle(i)}>
              <div className={classes.question}>{ques.question}</div>
              <div
                className={`${
                  clicked === i ? classes.answer : classes.noAnswer
                }`}
              >
                {ques.answer}
              </div>
              <span className={classes.btn}>+</span>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Faq;
