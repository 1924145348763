import React, { Fragment, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import App from "./App";

import Header from "./components/Header/Header";
import Loader from "./UI/Loader/Loader";
import Footer from "./components/Footer/Footer";


const MyRoutes = () => {
  return (
    <Fragment>
      <Header />
      <main>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/home" element={<App />} />
            <Route path="/" element={<Navigate to="home" />} />
            <Route exact path="/events/*" element={<Navigate to="home" />} >
              <Route exact path="cultural" element={<Navigate to="home" />}  />
              <Route exact path="technical" element={<Navigate to="home" />}  />
              <Route exact path="games" element={<Navigate to="home" />}  />
            </Route>
            <Route path="/events" element={<Navigate to="home" />}  />
            <Route path="/gallery" element={<Navigate to="home" />}  />
            <Route path="/tickets" element={<Navigate to="home" />}  />
          </Routes>
        </Suspense>
      </main>
      <Footer />
    </Fragment>
  );
};

export default MyRoutes;
